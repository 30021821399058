// Your variable overrides
$primary: #06283D;

$theme-colors: (
  'primary': $primary,
  'secondary': #f6c300,
  'danger': #911444,
  'success': #619f13,
  'warning': #f6c300,
  'info': #0068a5
);

$gray-100: #f8f7f7;
$gray-200: #d4d3d8;
$gray-300: #a4a1aa;
$gray-400: #928e9e;
$gray-500: #797584;
$gray-600: #635f6f;
$gray-700: #514c5d;
$gray-800: #35313f;
$gray-900: #222027;

// Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

@import './index.scss';
